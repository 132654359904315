import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";

// Styled component for sponsor images
const SponsorImage = styled("img")({
  width: "100%",
  height: "auto", // Adjust height to maintain aspect ratio
  maxHeight: "100px", // Maximum height to keep images uniform
  objectFit: "contain",
  borderRadius: "20px", // Rounded corners for the images
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

interface SponsorSectionProps {
  images?: string[];
}

const SponsorSection: React.FC<SponsorSectionProps> = ({ images = [] }) => {
  return (
    <Box
      sx={{
        padding: "2rem",
        textAlign: "center",
        bgcolor: 'rgba(211, 211, 211, 0.4)',
        mt: 4, // Adjust margin to fit your layout
        borderRadius: "20px", // Rounded corners for the container
        boxShadow: 2, // Optional: add shadow for better visual separation
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {images.map((image, index) => (
          <Grid item xs={12} sm={4} md={3} key={index}>
            <SponsorImage src={image} alt={`Sponsor ${index + 1}`} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SponsorSection;
