import React from "react";
import { Container } from "@mui/material";
import Section from "../components/Section";
import Whatwedo from "../components/WhatWedo";
import OurWork from "../components/OurWork";
import OurGoal from "../components/ourGoals";
import ContactSection from "../components/contactpage";
import Sponsor from "../components/ourSponsor";
import PhoneGallery from "../components/GalleryImg";
import GoalImage from "../assets/SiteOVW.jpg";
import image3 from "../assets/team3.jpg";
import image4 from "../assets/teams4.jpg";

const images = [
    GoalImage, image3, image4
];

const Homepage: React.FC = () => {
    const sectionProps = {
        title: "Who we are",
        subtitle: "Building a company from a small operation to one that spans all across Canada with a reputation for quality work is all based on our history, our mission, and the values we live by every day.",
        buttons: [{ text: "Contact Us", to: "/contact" }],
    };

    const ourGoalProps = {
        title: "Our Goal",
        goalDescription: "In order to excel in our industry, we must evolve as technology does, cultivating an environment of knowledge and growth. We aim to be a contractor that our clients can always rely on to meet or exceed their expectations. Safety, quality, and character are paramount to our ongoing success, and what we feel sets us apart. Additionally, the importance of a healthy work environment and interpersonal relationships cannot be understated. It is our belief that a small business is like a family, and employees should be treated in kind.\n Work should be as enjoyable and rewarding as it is challenging. We invite you to explore our website and learn more about who we are.",
        image: GoalImage,
        buttons: [{ text: "Learn More", to: "/about" }],
    };

    return (
        <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 }, mt: { xs: 2, md: 5 } }}>
            <Section
                title={sectionProps.title}
                subtitle={sectionProps.subtitle}
                buttons={sectionProps.buttons}
            />
            <OurWork />
            <Whatwedo />
            <OurGoal {...ourGoalProps} />
            <Sponsor />
            <PhoneGallery images={images} title="Our Gallery" />
            <ContactSection />
        </Container>
    );
};

export default Homepage;
