import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import SponsorImage2 from "../assets/ericsson.png";
import SponsorImage3 from "../assets/nokia.png";
import SponsorImage4 from "../assets/t-mobile.png";
import SponsorImage7 from "../assets/OIP (1).jpeg";

const Sponsors: React.FC = () => {
  const sponsorImages = [
    SponsorImage2,
    SponsorImage3,
    SponsorImage4,
    SponsorImage7,
  ];

  return (
    <Box sx={{ mt: 5, py: 4, backgroundColor: "#1b3823" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}
        >
          Our Sponsors
        </Typography>
        
       
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {sponsorImages.map((image, index) => (
          <Grid item xs={6} sm={3} md={2} key={index}>
            <Box
              component="img"
              src={image}
              alt={`Sponsor ${index + 1}`}
              sx={{
                width: { xs: "60px", sm: "70px", md: "80px" },
                height: { xs: "60px", sm: "70px", md: "80px" },
                borderRadius: "20%",
                border: "1px solid #ccc",
                boxShadow: 3,
                backgroundColor: "#ffffff",
                margin: "0 auto",
                display: "block",
                mb:5,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sponsors;
