import React from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ContactButton: React.FC = () => {
  return (
    <>
  
    <Button
      component={RouterLink} // Use RouterLink for navigation
      to="/contact" // Navigation path
      variant="contained"
      color="success"
      sx={{
        minWidth: 0,
        padding: "6px 16px",
        margin: "0 8px",
        borderRadius: "8px",
        fontSize: "1rem",
        textTransform: "none",
        display: "inline-flex",
        flexShrink: 0,
        backgroundColor: "#F0AD01", // Use backgroundColor for consistency
        "&:hover": {
          backgroundColor: "#fffbf1",
          color: "black",
        },
      }}
    >
      Contact Us
    </Button>
    </>
  );
};

export default ContactButton;
