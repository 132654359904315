import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, useTheme } from '@mui/material';
import VP from "../assets/vp.jpg"
import CEO from "../assets/Ceo.png"
import Israel from "../assets/Israel.png"
const teamMembers = [
  { name: 'Babatunde Raimi - CEO/President', position: 'BSC, CCNA,CISM', imageUrl: CEO },
  { name: 'Catherine Raimi - CEO/President ', position: ' PMP, CISM ', imageUrl: VP },
  { name: 'Israel Ogunsua - Senior Project Manger ', position: ' AWS, CompTia A+ ', imageUrl: Israel },
  // Add more team members as needed
];

const TeamSection = () => {
  const theme = useTheme();

  return (
    <Container sx={{ my: 6 }}>
      <Typography variant="h4" gutterBottom sx={{ justifyContent: "center", alignItems:"center",fontWeight: "bold", color: theme.palette.primary.main, mb: 4 }}>
        Our Team
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345, textAlign: 'center' }}>
              <CardMedia
                component="img"
                height="400"
                image={member.imageUrl}
                alt={member.name}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {member.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {member.position}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeamSection;
