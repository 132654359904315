import React from "react";
import { Typography, Container } from "@mui/material";
import ContactSection from "../components/contactpage";
import ServicePages from "../components/ServicePages";
import PhoneGallery from "../components/GalleryImg";
import image1 from "../assets/wirelessOWV.jpg"
import image2 from "../assets/power.jpg"
import image3 from "../assets/Access.jpg"

const images = [
  image1, image2, image3
  // Add more image URLs here
];

const Services2: React.FC = () => {
    const services = [
        
        {
            title: "Wireless Internet",
            description: (
                <>
                    <Typography variant="body2" paragraph>
                    Greenfield International Technologies Inc. is a leading provider of high-speed wireless broadband solutions tailored to meet the needs of residential, business, and rural customers. Our state-of-the-art technology and expert team are dedicated to delivering reliable, high-performance internet services with a commitment to excellence and customer satisfaction. We leverage advanced wireless technologies to offer robust connectivity solutions, ensuring seamless communication and internet access across diverse regions.
                    </Typography>
                    <Typography variant="body2" color={"inherit"} paragraph> High-Speed Wireless Broadband</Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                            Residential Internet: Enjoy fast, reliable, and secure internet access for your home with flexible plans designed to fit your usage needs.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                            Business Internet: Solutions tailored to business requirements, offering scalable speeds and robust connectivity to support productivity and growth.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                            Rural and Remote Connectivity: Bridging the connectivity gap in underserved areas with high-speed wireless solutions, bringing internet access to remote locations.
                            </Typography>
                        </li>
                       
                    </ul>

                    <Typography variant="body2" paragraph>Voice and Fax Solutions</Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                            Voice Services: Reliable and clear voice communication options for both residential and business customers, including VoIP services with advanced features.
                            </Typography>
                        </li>
                    </ul>

                    <Typography variant="body2" color={"inherit"} paragraph>  I.T. Management and Consulting</Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                            Network Design and Implementation: Expert design and deployment of network infrastructures to meet your specific needs, ensuring optimal performance and security.</Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                            System Maintenance and Support: Comprehensive support services for your IT systems, including troubleshooting, updates, and management to keep your operations running smoothly.</Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                            Consulting Services: Strategic IT consulting to help you make informed decisions about technology investments and improvements.</Typography>
                        </li>
                       
                    </ul>


                    <Typography variant="body2" color={"inherit"} paragraph> Networking Solutions</Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                            Custom Network Solutions: Tailored networking solutions to address unique business needs, including local area networks (LAN), wide area networks (WAN), and wireless networks. </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                            Network Security: Advanced security measures to protect your data and network from threats, ensuring secure and uninterrupted connectivity.</Typography>
                        </li>
                       
                    </ul>
                </>
            ),
           
            link: "/contact",
        },
    ];

    return (
        <>
            <Container sx={{ paddingTop: "2rem" }}>
                <ServicePages services={services} />
                <div>
                  <PhoneGallery images={images} title="Projects Images " />
                </div>
            </Container>
            
       
        <ContactSection/>
</>
    );
};

export default Services2;