import React from "react";
import { Box, Grid, Typography, Button, Container, Fade } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import VideoSection from "./GalleryVedio";

// Define interface for button properties
interface ButtonCon {
    text: string;
    to: string;
}

// Define interface for OurGoals component props
interface OurGoalProps {
    title: string;
    goalDescription: string;
    image?: string;
    video?: string[];
    buttons?: ButtonCon[];
}

// Styled component for animated image
const AnimatedBox = styled("img")({
    width: "100%",
    maxHeight: "400px", // Ensure the height is in a unit (e.g., px)
    borderRadius: "5px", // Border radius should be specified with units
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow needs a string with units
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
        transform: "scale(1.1)",
    },
});

// OurGoals component
const OurGoals: React.FC<OurGoalProps> = ({ title, goalDescription, image, video, buttons }) => {
    return (
        <Container maxWidth="lg" sx={{ mt: 10, px: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", color: "#247B27", textAlign: { xs: "center", md: "left" } }}>
                {title}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 3,
                    borderRadius: 2,
                    width: { xs: "50px", sm: "75px", md: "100px" },
                    height: "4px",
                    backgroundColor: "#77B3DF",
                    mb: 3,
                }}
            ></Box>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={7}>
                    <Box sx={{ pr: { xs: 0, md: 4 }, textAlign: { xs: "center", md: "left" } }}>
                        <Typography variant="body2" sx={{ color: "black" }} paragraph>
                            {goalDescription}
                        </Typography>
                        {buttons && buttons.length > 0 && (
                            <Grid container spacing={2} justifyContent={{ xs: "center", md: "flex-start" }}>
                                {buttons.map((button, index) => (
                                    <Grid item key={index}>
                                        <Fade in timeout={500}>
                                            <Button
                                                component={RouterLink}
                                                to={button.to}
                                                variant="contained"
                                                color="primary"
                                                sx={{ borderRadius: 20, padding: "10px 20px" }}
                                            >
                                                {button.text}
                                            </Button>
                                        </Fade>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    {video && <VideoSection videos={video} />}
                    {image && (
                        <AnimatedBox
                            src={image}
                            alt="Goals"
                            sx={{ mt: { xs: 3, md: 0 } }}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default OurGoals;
