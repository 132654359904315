import React, { useState, useRef } from "react";
import { Box, Grid, Modal, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import SponsorSection from "./SponsorUnder"; // Import the SponsorSection component

// Styled components
const GalleryVideo = styled("video")({
  width: "100%",
  height: "auto", // Adjust height to maintain aspect ratio
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const ModalVideo = styled("video")({
  width: "100%",
  height: "80vh", // Larger height for the modal video
});

interface VideoSectionProps {
  videos: string[];
  title?: string;
  sponsorImages?: string[]; // Add sponsorImages prop
}

const VideoSection: React.FC<VideoSectionProps> = ({ videos, sponsorImages }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleOpen = (video: string) => {
    setSelectedVideo(video);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (videoRef.current) {
      videoRef.current.pause(); // Pause video when modal is closed
      videoRef.current.currentTime = 0; // Reset video to start
    }
  };

  return (
    <Box sx={{ padding: "1rem", height: "100%", display:"block", flexDirection: "column" }}>
      
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {videos.map((video, index) => (
          <Grid item xs={12} key={index} sx={{ height: "50%" }}>
            <GalleryVideo 
              src={video} 
              controls
              onClick={() => handleOpen(video)} 
             
            />
          </Grid>
        ))}
      </Grid>

      {/* SponsorSection taking full width and height below the video section */}
      <Box sx={{ flexGrow: 1 }}>
        <SponsorSection images={sponsorImages}/>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedVideo && (
            <ModalVideo
              ref={videoRef}
              src={selectedVideo}
              controls
              autoPlay
              playsInline
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default VideoSection;
