import React from "react";
import { Typography, Container, Grid, Box } from "@mui/material";
import ContactSection from "../components/contactpage";
import ServicePages from "../components/ServicePages";
import PhoneGallery from "../components/GalleryImg";
import image1 from "../assets/SiteOVW.jpg"
import image2 from "../assets/towerView.jpg"
import image3 from "../assets/towerView2.jpg"
import image4 from "../assets/teams.jpg"
import image5 from "../assets/towerView3.jpg"
import image6 from "../assets/SiteOVW2.jpg"
import VideoSection from "../components/GalleryVedio";
import Vid1 from "../assets/BGVID.mp4"
import BoxRow from "../components/Rowbox";


const images = [
  image1, image2, image3, image4, image5, image6
  // Add more image URLs here
];

const videos = [
    Vid1
]


const Services4: React.FC = () => {
    const services = [
        {
            title: "Tower Install and Maintaince",
            description: (
                <>
                    <Typography variant="body2" paragraph>
                        At Greenfield International Technologies Inc., we know that a fully functional tower is the key to good business. Our repair and maintenance services keep you up and running – from anchor replacement to storm damage repairs, we’re always prepared for the unexpected. We specialize in catching problems before they start. With structural analysis and upgrades, foundation inspection, and grounding replacement, our tower maintenance services can help you avoid bigger repairs down the line. 
                     </Typography>

                    <Typography variant="body2" paragraph>
                    We’re happy to work with guyed, monopole, rotating, and self-supporting towers, and adhere to a range of strict industry standards. Greenfield also offers around the clock emergency service, so you’ll never have to go it alone. To inquire about our construction, repair, and maintenance services, contact us @ +1 438-923-9517
                    </Typography>
                   
                   
                </>
            ),
          
            link: "/contact",
        },
        
    ];

    return (
        <>
            <Container sx={{ paddingTop: "2rem" }}>
                <Box sx={{ }}>
                    <Grid container spacing={2} sx={{ height: "100%" }}>
                        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                            <ServicePages services={services} />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                            <VideoSection videos={videos}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <BoxRow/>
            <Container>
                <div>
                  <PhoneGallery images={images} title="Job Sites " />
                </div>
               
            </Container>
       
        <ContactSection/>
</>
    );
};

export default Services4;