import React from "react";
import {createRoot} from "react-dom/client";
import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter as Router} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import App from "./App";
import theme from "./components/Theme";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";


const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        
        <Auth0Provider
            domain="dev-5xly65ladcx040g8.us.auth0.com"
            clientId="ohr6hxWf6oFMPlJYKdjzYRbXJqGMDghq"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <DevSupport ComponentPreviews={ComponentPreviews}
                                useInitialHook={useInitial}
                    >
                        <App/>
                    </DevSupport>
                </Router>
            </ThemeProvider>
        </Auth0Provider>
       
    </React.StrictMode>
);
