import React from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import image from "../assets/siteOVW1.jpg"
import image1 from "../assets/wirelessOWV.jpg"
import image3 from "../assets/shelter.jpg"
import image4 from "../assets/teams.jpg"
import image5 from "../assets/towerView3.jpg"
import image6 from "../assets/SiteOVW2.jpg"
import VideoSection from "../components/GalleryVedio";
import Vid1 from "../assets/BGVID.mp4"
import PhoneGallery from '../components/GalleryImg';

const images = [
  image1, image3, image4, image5, image6
  // Add more image URLs here
];

const videos = [
    Vid1
]


const projects = [
  {
    title: 'Network Infrastructure Upgrade',
    description: 'Upgraded network infrastructure for a large enterprise, including the installation of new routers, switches, and network security measures.',
    image: image,
    link: '/project1',
  },
  {
    title: 'Cloud Migration',
    description: 'Migrated company data and applications to a cloud platform, improving scalability and reducing costs.',
    image: image1,
    link: '/project2',
  },
  {
    title: 'IT Support Services',
    description: 'Provided ongoing IT support services, including troubleshooting, maintenance, and user support for a diverse client base.',
    image: image3,
    link: '/project4',
  },
];

const ProjectPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#247B27' }}>
          Our Projects
        </Typography>
        <Box
          sx={{
            width: '120px',
            height: '4px',
            bgcolor: '#77B3DF',
            borderRadius: 2,
            margin: '0 auto',
          }}
        />
      </Box>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200"
                image={project.image}
                alt={project.title}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {project.description}
                </Typography>
              </CardContent>
              {/* <Box sx={{ textAlign: 'center', pb: 2 }}>
                <Button
                  component={RouterLink}
                  to={project.link}
                  variant="contained"
                  color="primary"
                  sx={{ bgcolor: '#F0AD01', '&:hover': { bgcolor: '#388e3c' } }}
                >
                  Learn More
                </Button>
              </Box> */}
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body2" color="text.secondary">
          For more information about our projects, please <RouterLink to="/contact">contact us</RouterLink>.
        </Typography>
      </Box>
      <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <VideoSection videos={videos} title = "Project Gallery" />
      </Grid>
      <div>
        <PhoneGallery images={images} title="Job Sites " />
      </div>
    </Container>
  );
};

export default ProjectPage;
