import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  styled,
  Divider,
  useMediaQuery,
  Theme,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LoginIcon from "@mui/icons-material/Login";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(45deg, #FFFFFF, #FFFFFF, #AAB0AA, #8DD783, #084430)",
  color: "#FFFFFF", // White text color
  top: 0,
  zIndex: 1201,
  height: "56px",
  boxShadow: "none", // Removed shadow
  [theme.breakpoints.down("sm")]: {
    height: "48px",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: "56px",
  height: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "0 8px",
    minHeight: "48px",
  },
}));

const SocialIconsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const UpperNavigationBar: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleLogin = async () => {
    try {
      await loginWithRedirect({
        appState: { returnTo: "/admin" },
      });
    } catch (error) {
      console.error("[UpperNavigationBar] Login error:", error);
    }
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const buttonStyles = {
    color: "#FFFFFF", // White text
    borderColor: "#AAB0AA", // Gray border
    backgroundColor: "#8DD783", // Green background
    "&:hover": {
      backgroundColor: "#399A4B", // Darker green on hover
      color: "#FFFFFF", // White text on hover
      borderColor: "#6A6E6B",
    },
    padding: "6px 16px",
    minHeight: 0,
    lineHeight: 1.75,
    fontSize: isMobile ? "0.75rem" : "0.875rem",
    marginRight: "12px",
    textTransform: "none",
  };

  const iconButtonStyles = {
    color: "#FFFFFF", // White color for icons
    "&:hover": {
      backgroundColor: "rgba(170, 176, 170, 0.1)", // Gray with low opacity on hover
    },
  };

  const iconButtonSize = isMobile ? "small" : "medium";

  return (
    <StyledAppBar position="relative">
      <StyledToolbar>
        {isAuthenticated && (
          <Button
            component={Link}
            to="/admin"
            variant="contained"
            size="small"
            startIcon={<DashboardIcon />}
            sx={buttonStyles}
          >
            Admin Dashboard
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          startIcon={isAuthenticated ? <LogoutOutlinedIcon /> : <LoginIcon />}
          onClick={isAuthenticated ? handleLogout : handleLogin}
          sx={buttonStyles}
        >
          {isAuthenticated ? "Logout" : "Login"}
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#FFFFFF", height: "24px", mx: 2 }}
        />
        <SocialIconsBox>
         
            <IconButton
              key={"LinkedIn" }
              href={`https://www.linkedin.com/in/babatunde-raimi-70b623252/`}
              target="_blank"
              rel="noopener noreferrer"
              size={iconButtonSize}
              sx={iconButtonStyles}
            >
          
           <LinkedInIcon fontSize="inherit" />
          
            </IconButton>

          
            <IconButton
              key={"Instagram"}
              href={`https://www.instagram.com/greenfieldinternational/`}
              target="_blank"
              rel="noopener noreferrer"
              size={iconButtonSize}
              sx={iconButtonStyles}
            >

              <InstagramIcon fontSize="inherit" />
            </IconButton>

        </SocialIconsBox>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default UpperNavigationBar;
