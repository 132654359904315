import React from "react";
import { Typography, Container } from "@mui/material";
import ContactSection from "../components/contactpage";
import ServicePages from "../components/ServicePages";
import PhoneGallery from "../components/GalleryImg";
import image1 from "../assets/SiteOVW.jpg"
import image2 from "../assets/siteOVW1.jpg"
import image3 from "../assets/shelter.jpg"
import image4 from "../assets/SiteOVW2.jpg"
import image5 from "../assets/shelter3.jpg"
import image6 from "../assets/SiteOVW4.jpg"
import image7 from "../assets/Shelter1.jpg"
import image8 from "../assets/power.jpg"
import image9 from "../assets/Tower1.jpg"

const images = [
  image1, image2, image3,  image4, image5, image6, image7, image8, image9, 
  // Add more image URLs here
];


const Services1: React.FC = () => {
    const services = [
        {
            title: "Telecommunications Services",
            description: (
                <>
                    <Typography variant="body2" paragraph>
                        At Greenfield International Technologies Inc., we specialize in providing advanced telecommunications solutions tailored to meet the unique needs of modern businesses. Our comprehensive services include:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                                Fiber optic network installation and maintenance
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Satellite communication systems integration
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Wireless network design and deployment
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                VoIP and unified communications solutions
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Telecommunication infrastructure upgrades and optimization
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body2" paragraph>
                        Whether you're looking to enhance connectivity, improve efficiency, or scale your telecommunications infrastructure, Greenfield is your trusted partner for innovative and reliable solutions.
                    </Typography>
                </>
            ),
          
            link: "/contact",
        },
        
    ];

    return (
        <>
            <Container sx={{ paddingTop: "2rem" }}>
                <ServicePages services={services} />
                <div>
                  <PhoneGallery images={images} title="Projects Images " />
                </div>
            </Container>
        
        <ContactSection/>
</>
    );
};

export default Services1;