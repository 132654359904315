import React, { useState } from "react";
import { Box, Grid, Typography, Modal } from "@mui/material";
import { styled } from "@mui/system";

// Styled components
const GalleryImage = styled("img")({
  width: "100%",
  height: "200px", // Set a fixed height for consistency
  objectFit: "cover", // Ensure images cover the area without distortion
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const ModalImage = styled("img")({
  width: "100%",
  height: "auto",
});

// TypeScript interfaces for props
interface PhoneGalleryProps {
  images: string[];
  title?: string;
}

const PhoneGallery: React.FC<PhoneGalleryProps> = ({ images, title }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpen = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ padding: "1rem" }}>
      {title && (
        <Typography variant="h4" sx={{ marginBottom: "1rem", textAlign: "center", fontWeight: "bold", color: "#247B27" }}>
          {title}
        </Typography>
        
      )}
      <Box
          sx={{
            width: '100px',
            height: '5px',
            bgcolor: '#77B3DF',
            borderRadius: 2,
            margin: '0 auto',
            mb: 3,
          }}

        />
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <GalleryImage 
              src={image} 
              alt={`Gallery image ${index + 1}`} 
              onClick={() => handleOpen(image)} 
            />
          </Grid>
        ))}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "15%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            maxHeight: "40%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
           
          }}
        >
          {selectedImage && <ModalImage src={selectedImage} alt="Selected" />}
        </Box>
      </Modal>
    </Box>
  );
};

export default PhoneGallery;
