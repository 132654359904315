import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import theme from "./Theme";
import Video from "../assets/BGVID.mp4";

interface ButtonConfig {
    text: string;
    to: string;
}

interface SectionProps {
    title: string;
    subtitle: string;
    additionalContent?: string[];
    buttons?: ButtonConfig[];
}

const Section: React.FC<SectionProps> = ({
    title,
    subtitle,
    additionalContent,
    buttons,
}) => {
    return (
        <>
            <video
                src={Video}
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                }}
            />

            <Container maxWidth="lg" sx={{ position: 'relative', padding: { xs: '1rem', sm: '2rem' },ml: { xs: 4},  mt: { xs: 15, sm: 20 }, mb: { xs: 28, sm: 23 } }}>
                <Grid container spacing={4} alignItems="center" sx={{ width: '100%', bgcolor: 'rgba(0, 0, 0, 0.4)', padding: '1rem', borderRadius: '8px' }}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{
                                fontWeight: "bold",
                                fontSize: { xs: '2rem', sm: '2.5rem' },
                                lineHeight: { xs: '2.5rem', sm: '3rem' },
                                color: theme.palette.secondary.main,
                                textShadow: '2px 2px 4px rgba(0,0,0,0.7)'
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body1"
                            paragraph
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                lineHeight: { xs: '1.5rem', sm: '2rem' },
                                color: "white",
                                textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
                            }}
                        >
                            {subtitle}
                        </Typography>

                        {additionalContent && (
                            <Box sx={{ marginTop: 2 }}>
                                {additionalContent.map((item, index) => (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        paragraph
                                        sx={{
                                            color: "white",
                                            textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        )}

                        {buttons && buttons.length > 0 && (
                            <Box sx={{ marginTop: 4 }}>
                                {buttons.map((button, index) => (
                                    <Button
                                        key={index}
                                        component={RouterLink}
                                        to={button.to}
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            marginRight: { xs: 0, sm: 2 },
                                            marginBottom: { xs: 1, sm: 0 },
                                            backgroundColor: theme.palette.secondary.main,
                                            "&:hover": { backgroundColor: theme.palette.primary.main },
                                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                            textTransform: 'none'
                                        }}
                                    >
                                        {button.text}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Section;
