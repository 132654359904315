import React, { Fragment } from "react";
import { Container, Typography, Box, useTheme, Grid } from '@mui/material';
import { useSpring, animated } from "@react-spring/web";
import OurGoal from "../components/ourGoals";
import Sponsor from "../components/ourSponsor";
import ContactSection from "../components/contactpage";
import EachFuc from "../components/Eachpage";
import about from "../assets/About.jpg";
import PhoneGallery from "../components/GalleryImg";
import image1 from "../assets/teams.jpg";
import image2 from "../assets/teams2.jpg";
import image3 from "../assets/team3.jpg";
import image4 from "../assets/teams4.jpg";
import image5 from "../assets/Tower1.jpg"
import VidTower from "../assets/TowerBuildVid1.mp4";
import TeamSection from "../components/Team";
import VideoSection from "../components/GalleryVedio";

// Define image and video arrays
const images = [image1, image2, image3, image4, image5];
const videos = [VidTower];
const GalleryVid = [VidTower];

const AboutPage: React.FC = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const theme = useTheme();

  return (
    <Fragment>
      {/* Section 1: Introduction */}
      <EachFuc Title="About Us" image={about} />

      {/* Section 2: Our Goals */}
      <OurGoal
        title="Our Goals"
        video={videos}
        goalDescription={`
          Our aim is to grow from a small operation into a nationwide company renowned for quality work. This journey is guided by our history, mission, and the values we uphold daily. By consistently delivering exceptional service and maintaining high standards, we aspire to expand our reach across Canada and The United States, establishing a strong reputation in every community we serve. Our commitment to excellence, innovation, and customer satisfaction will drive our growth and success, making us a trusted name in the industry.
        `}
      />

      {/* Section 3: Values and Mission */}
      <Container sx={{ my: 6 }}>
        <animated.div style={fadeIn}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", color: theme.palette.primary.main, mb: 4 }}
          >
            Our Values
          </Typography>

          {["Safety", "People", "Quality"].map((value, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                {value}
              </Typography>
              <Typography variant="body1" paragraph>
                {value === "Safety" && `We prioritize safety above all else, adhering to a "zero incidents" philosophy because we believe all incidents are preventable. We continuously enhance our Health, Safety, and Environment (HS&E) practices, fostering a culture where employees and subcontractors actively evaluate and improve safety measures. Our goal is to ensure that everyone returns home safely to their families every day.`}
                {value === "People" && `We hire the best professionals in the industry, providing them with ongoing training and investing in their long-term career development. We cultivate a unified culture, leveraging each other’s experience and industry-leading expertise to deliver exceptional results in a rewarding workplace.`}
                {value === "Quality" && `Our goal is to complete every project correctly the first time, every time, and we stand behind our products and services. This commitment to quality is integrated into every step of the process, not just during the final inspection.`}
              </Typography>
            </Box>
          ))}

          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", color: theme.palette.primary.main, mt: 6 }}
          >
            Our Mission
          </Typography>
          <Typography variant="body1">
            To become North America's leading provider of comprehensive connectivity solutions, prioritizing safety and consistently exceeding the expectations of our clients and communities.
          </Typography>
        </animated.div>
      </Container>

      {/* Section 4: Team Section */}
      <Box sx={{ my: 4 }}>
        <TeamSection />
      </Box>

      {/* Section 5: Gallery */}
      <Container sx={{ my: 8}}>
        <PhoneGallery images={images} title="Our Teams" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <VideoSection videos={GalleryVid} />
          </Grid>
        </Grid>
      </Container>

      {/* Section 6: Sponsors */}
      <Box sx={{ my: 4 }}>
        <Sponsor />
      </Box>

      {/* Section 7: Contact */}
      <Box sx={{ my: 4 }}>
        <ContactSection />
      </Box>
    </Fragment>
  );
};

export default AboutPage;
