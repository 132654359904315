import React from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";

interface BoxData {
  title: string;
  items: string[];
}

const boxData: BoxData[] = [
  {
    title: "Repair Capabilities",
    items: [
      "Anchor Replacement",
      "Cathodic Protection",
      "Deconstruction",
      "Guy Wire Treatment",
      "Install & Maintenance",
      "Lighting Protection System",
      "Pole Change-Outs & Transfers",
      "Refurbishment",
      "Relamping",
      "Restructuring",
    ]
  },
  {
    title: "Industry Focus",
    items: [
      "Broadcast",
      "Radio",
      "Television",
      "Cellular",
      "Government",
      "Microwave",
      "Public Safety",
      "Wireless"
    ]
  },
  {
    title: "Maintenance",
    items: [
      "Foundation Inspection",
      "Guy Wire Tensioning & Realignment",
      "Replacement of Grounding",
      "Structural Analysis",
      "Structural Upgrades",
      "System Extensions"
    ]
  },
  {
    title: "Antenna/Line Type",
    items: [
      "Including but not limited to these antenna types",
      "HF",
      "UHF",
      "VHF",
      "Infrared",
      "Microwave"
    ]
  },
  {
    title: "Structure Type",
    items: [
      "Guyed",
      "Monopoles",
      "Rotating",
      "Self-Supporting",
      "Rooftops"
    ]
  },
];

const BoxRow: React.FC = () => {
  return (
    <Container sx={{ width: "100%", paddingTop: "2rem" }}>
      <Grid container spacing={4}>
        {boxData.map((box, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              sx={{
                borderRadius: "12px",
                boxShadow: 3,
                backgroundColor: "#f9f9f9",
                overflow: "visible",
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  {box.title}
                </Typography>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px", margin: 0 }}>
                  {box.items.map((item, idx) => (
                    <li key={idx}>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BoxRow;
