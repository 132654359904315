import React from "react";
import { Typography, Container, Grid, Box } from "@mui/material";
import ContactSection from "../components/contactpage";
import ServicePages from "../components/ServicePages";
import PhoneGallery from "../components/GalleryImg";
import image1 from "../assets/Ev1.jpeg"
import image2 from "../assets/Ev2.jpg"
import image3 from "../assets/EV3.jpg"
import image4 from "../assets/Ev4.jpg"
import image5 from "../assets/Ev5.jpg"
import image6 from "../assets/Ev6.jpeg"
import image7 from "../assets/Ev7.jpeg"
import VideoSection from "../components/GalleryVedio";
import Vid1 from "../assets/How+to+Charge+an+EV+with+ChargePoint.mp4"
import imageEv1 from "../assets/CHARGE-removebg-preview.png"
import imageEv2 from "../assets/star-removebg-preview.png"
import imageEv3 from "../assets/evo-removebg-preview.png"

const imageEv= [
  imageEv1, imageEv2, imageEv3
  // Add more image URLs here
];
const images = [
  image1, image2, image3, image4, image5, image6, image7
  // Add more image URLs here
];
const videos = [
    Vid1
]

const Services3: React.FC = () => {
    const services = [
        {
            title: "EV Charging Stations",
            description: (
                <>
                    <Typography variant="body2" paragraph>
                        Greenfield International Technologies Inc. is proud to be at the forefront of the electric vehicle revolution, providing state-of-the-art EV charging solutions to support the growing adoption of electric mobility. Our commitment to sustainability and innovation drives us to offer top-notch charging infrastructure that meets the needs of EV owners and supports a greener future.
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                                High-Speed Charging Stations: We offer a range of high-speed charging stations designed to deliver fast and efficient charging for all types of electric vehicles. Our stations are equipped with the latest technology to ensure rapid and reliable charging.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Smart Charging Solutions: Our smart charging solutions integrate advanced features such as remote monitoring, usage analytics, and energy management to optimize the charging experience and reduce costs.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Installation and Maintenance: We provide comprehensive installation services for residential and commercial EV charging stations, as well as ongoing maintenance and support to ensure your charging infrastructure remains operational and efficient.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Customized Solutions: Whether you are looking for a single charging station for your home or a large-scale installation for a commercial property, we can design and implement a solution that meets your specific needs.
                            </Typography>
                        </li>
                    </ul>
                </>
            ),
            link: "/contact",
        }
    ];
    const services1 = [
            {
                title: "",
                description: (
                    <>
                  
                    <Typography variant="body2" paragraph>
                        We are honored to collaborate with leading sponsors in the EV industry to enhance our charging infrastructure and provide superior solutions for electric vehicle owners:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body2" paragraph>
                                ChargePoint+: Partnering with Charge+ to bring cutting-edge charging solutions to our customers, ensuring a seamless and efficient charging experience.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                Tesla: Working closely with Tesla to offer dedicated charging solutions for Tesla vehicles, including fast-charging options that align with Tesla’s high standards of performance and reliability.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" paragraph>
                                EV: Collaborating with EV to ensure our charging stations are compatible with a wide range of electric vehicles, providing flexibility and convenience for all EV owners.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body2" paragraph>
                        At Greenfield International Technologies Inc., we are committed to advancing the electric vehicle ecosystem and supporting the transition to a sustainable future. For more information about our EV charging solutions and how we can help you integrate this technology, please contact us.
                    </Typography>
        
                    </>
                ),
                link: "/contact",
            }
    ];

    return (
        <>

            <Container sx={{ paddingTop: "2rem" }}>
                <Box sx={{ }}>
                    <Grid container spacing={2} sx={{ height: "100%" }}>
                        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                            <ServicePages services={services} />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                            <VideoSection videos={videos} sponsorImages={imageEv} />
                        </Grid>
                    </Grid>
                </Box>
                <ServicePages services={services1} />
        
                <div>
                  <PhoneGallery images={images} title="Projects Images " />
                </div>
               
            </Container>
       
        <ContactSection/>
</>
    );
};

export default Services3;