import React from "react";
import { Container, Typography } from "@mui/material";

interface Service {
    title: string;
    description: React.ReactNode;
  
    link: string;
}

interface ServicePagesProps {
    services: Service[];
}

const ServicePages: React.FC<ServicePagesProps> = ({ services }) => {
    return (
        <>
            {services.map((service, index) => (
                <Container key={index}>
                    <Typography variant="h4" gutterBottom color="#247B27" >
                        {service.title}
                    </Typography>
                    {service.description}
                    
                   
                </Container>
            ))}
        </>
    );
};

export default ServicePages;
