import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  Drawer,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logo from "../assets/logo.png";
import ContactButton from "./ContactButton";
import { Link as RouterLink, useLocation } from "react-router-dom";

interface NavButtonProps {
  to: string;
  primary: string;
  hasDropdown?: boolean;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({
  to,
  primary,
  hasDropdown,
  onMouseEnter,
  onClick,
}) => (
  <Button
    component={RouterLink}
    to={to}
    sx={{
      color: "#FFFFFF", // Text color
      fontWeight: "bold",
      fontSize: "1rem",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#8DD783", // Hover background color
        color: "#FFFFFF", // Hover text color
      },
    }}
    onMouseEnter={hasDropdown ? onMouseEnter : undefined}
    onClick={onClick}
    endIcon={hasDropdown ? <KeyboardArrowDownIcon /> : null}
  >
    {primary}
  </Button>
);

const NavigationBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesAnchorEl, setServicesAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0); // Scroll to top on route change
    }
  }, [location.pathname]); // Trigger on pathname change

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent): void => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleServicesHover = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isMobile) {
      setServicesAnchorEl(event.currentTarget);
    }
  };

  const handleServicesClose = (): void => {
    setServicesAnchorEl(null);
    setMobileServicesOpen(false);
  };

  const handleMobileServicesClick = (): void => {
    setMobileServicesOpen((prev) => !prev);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        background: "linear-gradient(45deg, #FFFFFF, #AAB0AA, #8DD783, #399A4B)",
        color: "#FFFFFF",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Typography variant="h4" component="div" sx={{ marginLeft: "10px" }}>
          <RouterLink to="/" className="navbar-brand">
            <img
              src={logo}
              alt="Logo"
              className="logo-img"
              style={{ maxWidth: 150, height: "auto" }}
            />
          </RouterLink>
        </Typography>

        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              edge="end"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{
                  width: 250,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "1rem",
                  background: "linear-gradient(45deg, #FFFFFF, #FFFFFF, #AAB0AA, #8DD783, #084430)",
                  color: "#FFFFFF",
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <NavButtons onMouseEnter={handleServicesHover} onMobileServicesClick={handleMobileServicesClick} />
                {mobileServicesOpen && (
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <DropdownMenu handleClose={handleServicesClose} />
                  </Box>
                )}
                <ContactButton />
              </Box>
            </Drawer>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
              marginRight: "20px",
            }}
          >
            <NavButtons onMouseEnter={handleServicesHover} />
            <ContactButton />
          </Box>
        )}
      </Toolbar>

      <Menu
        anchorEl={servicesAnchorEl}
        open={Boolean(servicesAnchorEl)}
        onClose={handleServicesClose}
        MenuListProps={{ onMouseLeave: handleServicesClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DropdownMenu handleClose={handleServicesClose} />
      </Menu>
    </AppBar>
  );
};

const NavButtons: React.FC<{
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMobileServicesClick?: () => void;
}> = ({ onMouseEnter, onMobileServicesClick }) => (
  <>
    <NavButton to="/" primary="Home" />
    <NavButton to="/about" primary="About" />
    <NavButton
      to="/"
      primary="Services"
      hasDropdown
      onMouseEnter={onMouseEnter}
      onClick={onMobileServicesClick}
    />
    <NavButton to="/project" primary="Project" />
    <NavButton to="/careers" primary="Careers" />
  </>
);

const DropdownMenu: React.FC<{ handleClose: () => void }> = ({ handleClose }) => (
  <>
    <MenuItem
      component={RouterLink}
      to="/Telecommunications-Services"
      onClick={handleClose}
      sx={{
        color: "black",
        fontWeight: "bold",
        fontSize: "1rem",
        textTransform: "none",
        "&:hover": {
          border: 2,
          borderWidth: 1,
          borderColor: "black",
          color: "black",
        },
      }}
    >
      Telecommunications Services
    </MenuItem>
    <MenuItem
      component={RouterLink}
      to="/Wireless"
      onClick={handleClose}
      sx={{
        color: "black",
        fontWeight: "bold",
        fontSize: "1rem",
        textTransform: "none",
        "&:hover": {
          border: 2,
          borderWidth: 1,
          borderColor: "black",
          color: "black",
        },
      }}
    >
      Wireless Internet
    </MenuItem>
    <MenuItem
      component={RouterLink}
      to="/Ev"
      onClick={handleClose}
      sx={{
        color: "black",
        fontWeight: "bold",
        fontSize: "1rem",
        textTransform: "none",
        "&:hover": {
          border: 2,
          borderWidth: 1,
          borderColor: "black",
          color: "black",
        },
      }}
    >
      EV Charging Station
    </MenuItem>
    <MenuItem
      component={RouterLink}
      to="/Tower-Repair"
      onClick={handleClose}
      sx={{
        color: "black",
        fontWeight: "bold",
        fontSize: "1rem",
        textTransform: "none",
        "&:hover": {
          border: 2,
          borderWidth: 1,
          borderColor: "black",
          color: "black",
        },
      }}
    >
      Tower Repair and Maintenance
    </MenuItem>
  </>
);

export default NavigationBar;
