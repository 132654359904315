import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import UpperNavigationBar from "./components/UpperNavigationBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Services from "./pages/Services";
import Project from "./pages/Project";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Contact from "./pages/Contact";
import ProtectedRoute from "./components/ProtectedRoute";
import PageTransition from './components/PageTransition';
import Services1 from "./pages/service1";
import Services2 from "./pages/service2";
import Services3 from "./pages/service3";
import Services4 from "./pages/service4";

// import ClientsPage from "./pages/Clients";

const App: React.FC = () => {
    const location = useLocation();

    return (
        <>
            <UpperNavigationBar data-testid="upperNavBar" />
            <NavigationBar data-testid="navBar"/>
            <PageTransition>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/project" element={<Project />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/Telecommunications-Services" element={<Services1 />} />
                    <Route path="/Wireless" element={<Services2 />} />
                    <Route path="/Ev" element={<Services3 />} />
                    <Route path="/Tower-Repair" element={<Services4 />} />
                    
                    
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute>
                                <Admin />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </PageTransition>
            <Footer data-testid="footer" />
        </>
    );
};

export default App;