import React from "react";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ServiceImage1 from "../assets/SiteOVW.jpg";
import ServiceImage2 from "../assets/shelter.jpg";
import ServiceImage3 from "../assets/towerView3.jpg";

const services = [
  {
    title: "Telecommunications Services",
    description:
      "Greenfield is a communications company specializing in designing and implementing communication systems for industrial applications. We plan and install communication networks, including towers, antennas, and repeaters for UHF data transfer (Spread-link, SCADA, cellular) and VHF 2-way voice communications across Canada. We handle applications and forms for tower and radio frequency usage, ensuring client compliance with government regulations. Our clients span various industries, including oil and gas, mining, forestry, utilities, and broadcasting.",
    image: ServiceImage1,
    link: "/about",
  },
  {
    title: "Wireless Internet",
    description:
      "At Greenfield, we offer comprehensive internet solutions for both residential and business needs, including high-speed wireless broadband. Our services extend to voice and fax solutions, I.T. management through our partner, Ericsson Canada, Revenant Costrustion Group, LLC, and expert networking and consulting. With competitive pricing and near line of sight coverage, we cater to a wide range of locations, including rural and remote areas.",
    image: ServiceImage1,
    link: "/service2",
  },
  {
    title: "Tower Installation And Maintenance",
    description:
      "We design, construct, and install various communication towers, ensuring all are weld and CSA approved. Our services include site selection, lease agreements, and obtaining necessary government approvals. We also handle maintenance and repairs for both towers and communications equipment. Our team includes certified tower riggers and climbers, and we offer picker truck and man-basket services for installations and work on non-CSA approved towers.",
    image: ServiceImage2,
    link: "/service3",
  },
  {
    title: "EV Charging Station",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    image: ServiceImage3,
    link: "/service4",
  },
];

const WhatWeDo: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 10, px: { xs: 2, sm: 3, md: 4 } }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#247B27' }}>
          What We Do
        </Typography>
        <Box
          sx={{
            width: '100px',
            height: '3px',
            bgcolor: '#77B3DF',
            borderRadius: 2,
            margin: '0 auto',
          }}
        />
      </Box>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                bgcolor: 'background.paper',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.03)',
                },
              }}
            >
              <img
                src={service.image}
                alt={service.title}
                style={{ width: '100%', height: '150px', objectFit: 'cover', borderRadius: '4px' }}
              />
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#247B27' }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontSize: '0.875rem' }}>
                  {service.description}
                </Typography>
                <Button
                  component={RouterLink}
                  to={service.link}
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    bgcolor: '#F0AD01',
                    fontSize: '0.875rem',
                    '&:hover': { bgcolor: '#388e3c' },
                  }}
                >
                  Learn More
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WhatWeDo;
