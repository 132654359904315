import React from "react";
import EachFuc from "../components/Eachpage";
import CareerPage from '../components/Career';
import Page from "../assets/About.jpg";
import Sponsor from "../components/ourSponsor";

const Careers: React.FC = () => {
    const jobs = [
        {
            title: 'Telecommunication  Engineer ',
            description: 'Join our team as a frontend developer and work on exciting projects.',
            color: '#F0AD01',
        },
        {
            title: 'Tower Rigger',
            description: 'We are looking for a talented UX designer to improve our user experience.',
            color: '#77B3DF', // second job color
        },
        {
            title: 'EV Technician',
            description: 'We are looking for a talented UX designer to improve our user experience.',
            color: '#77B3DF', // second job color
        },
        {
            title: 'Network Specialist',
            description: 'We are looking for a talented UX designer to improve our user experience.',
            color: '#77B3DF', // second job color
        },
    ];

    return (
        <>
            <EachFuc
                Title="Career"
                image={Page}
            />
            <CareerPage jobs={jobs} />
            <Sponsor />
        </>
    );
};

export default Careers;